import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getManageDevicesSuccess,
  getDevicesManagedSuccess,
  setAssignedDevicesFailure,
  setManagedDevicesFailure,
} from './actions';
import { GET_MANAGE_DEVICES_ATTEMPT, SET_DEVICES_MANAGED } from './actionTypes';
import { getDataFromService, postDataToService } from '../../services/apiGatewayClient';
import { TypedIterableIterator } from '../../modules/helpers';
import { SelectState } from '../../modules/helpers';
import { getDevicesAddedSelector, getDevicesRemovedSelector } from './selectors';
import { getSelectedSubscriptionSelector } from '../SubscriptionsList/selectors';
import { getBillingProviderSelector } from '../BrandProvider/selectors';
import { STRIPE_ELEMENTS } from '../../constants/providerNames';

function* getDevicesToManage(): TypedIterableIterator<any> {
  try {
    const devices = yield call(getDataFromService, '/devices', 'account', {
      excludeExternalSub: false,
    }) ?? [];

    const subscribableDevices = devices.filter((device: any) => device.subscribable !== false);

    yield put(getManageDevicesSuccess(subscribableDevices));
  } catch (err) {
    yield put(setAssignedDevicesFailure(err));
  }
}

function* setDevicesAsManaged(): TypedIterableIterator<any> {
  try {
    const addedDevices: any = yield SelectState<any>(getDevicesAddedSelector);
    const removedDevices: any = yield SelectState<any>(getDevicesRemovedSelector);
    const selectedSubscription: any = yield SelectState<any>(getSelectedSubscriptionSelector);
    const billingProvider = yield SelectState<any>(getBillingProviderSelector);
    const isV3 = billingProvider === STRIPE_ELEMENTS;

    let subscriptionInfo = createSubscriptionRequest(addedDevices, removedDevices, selectedSubscription, isV3);
    let subscriptionResponse = yield call(postDataToService, '/subscribe', subscriptionInfo, 'subscriptions');

    if (subscriptionResponse.Payload) {
      let subscriptionPayload = JSON.parse(subscriptionResponse.Payload);
      if (subscriptionPayload.FunctionError) {
        const errorPayload = JSON.parse(subscriptionPayload.Payload);
        const err = new Error(errorPayload.errorType);
        yield put(setManagedDevicesFailure(err));
        return;
      }
    }

    yield put(getDevicesManagedSuccess());
  } catch (err) {
    yield put(setManagedDevicesFailure(err));
  }
}

function createSubscriptionRequest(devicesAdded: any, devicesRemoved: any, selectedSubscription: any, isV3: boolean) {
  return {
    pepperPlanId: selectedSubscription.plan_config_id,
    pepperSubscriptionId: selectedSubscription.id,
    devices: createDeviceObjectFromList(devicesAdded),
    devicesRemoved: createDeviceObjectFromList(devicesRemoved),
    targetPlanCode: selectedSubscription.plan_config.external_plan_code,
    currentSubscriptionUuid: selectedSubscription.external_subscription_id,
    flow: 'management',
    isV3: isV3,
  };
}

function createDeviceObjectFromList(devices: any) {
  let localDevices = [];
  if (devices) {
    for (let device of devices) {
      let localDevice = {
        deviceId: device.pepperDeviceId,
        externalDeviceId: device.deviceId,
        deviceProvider: device.provider,
        deviceModel: device.model,
      };
      localDevices.push(localDevice);
    }
  }
  return localDevices;
}

function* ManageDevicesSaga() {
  yield all([takeLatest(GET_MANAGE_DEVICES_ATTEMPT, getDevicesToManage)]);
  yield all([takeLatest(SET_DEVICES_MANAGED, setDevicesAsManaged)]);
}

export default ManageDevicesSaga;
