import { all, put, takeLatest, call } from 'redux-saga/effects';
import { SelectState, TypedIterableIterator } from '../../modules/helpers';
import { postDataToService } from '../../services/apiGatewayClient';
import * as types from './actionTypes';
import * as actions from './actions';
import history from '../../history';
import { getBillingProviderSelector } from '../BrandProvider/selectors';
import { loadingBillingInfoView } from '../BillingInformationView/actions';
import { LOADING_COUNTRY, STORE_BILLING_INFO_IN_STORE } from '../BillingInformationView/actionTypes';
import { STRIPE_ELEMENTS } from '../../constants/providerNames';

const STRIPE_CONFIRMATION_TOKEN_REFERENCE_TYPE = 'stripe_confirmation_token';

function* storeBillingInfoStripeSaga(action: any): TypedIterableIterator<any> {
  try {
    const billingProvider = yield SelectState<any>(getBillingProviderSelector);
    const billingToken = action.payload.selectedBillingInfo.token;
    let postUrl = `/billingInfo${billingProvider === STRIPE_ELEMENTS ? 'V3' : ''}/${billingToken}`;

    const billingInfo = {
      firstName: action.payload.selectedBillingInfo.first_name,
      lastName: action.payload.selectedBillingInfo.last_name,
      paymentGatewayReferences: [
        {
          token: action.payload.selectedBillingInfo.token,
          referenceType: STRIPE_CONFIRMATION_TOKEN_REFERENCE_TYPE,
        },
      ],
    };

    const billingInfoResponse = yield call(postDataToService, postUrl, billingInfo, 'subscriptions');

    yield put(actions.storeBillingInfoStripeSuccess(billingInfoResponse));
    yield put(loadingBillingInfoView());

    history.push('/payment');
  } catch (error: any) {
    yield put(actions.storeBillingInfoStripeFailure(error));
  }
}

function* verifyBillingInfoStripeSaga(action: any): TypedIterableIterator<any> {
  try {
    const billingProvider = yield SelectState<any>(getBillingProviderSelector);
    let postUrl = `/billing/verify${billingProvider === STRIPE_ELEMENTS ? 'V3' : ''}`;

    const billingInfo = {
      firstName: action.payload.selectedBillingInfo.first_name,
      lastName: action.payload.selectedBillingInfo.last_name,
      address: {
        street1: action.payload.selectedBillingInfo.address1,
        city: action.payload.selectedBillingInfo.city,
        region: action.payload.selectedBillingInfo.state,
        postalCode: action.payload.selectedBillingInfo.postal_code,
        country: action.payload.selectedBillingInfo.country,
      },
      paymentGatewayReferences: [
        {
          token: action.payload.selectedBillingInfo.token,
          referenceType: STRIPE_CONFIRMATION_TOKEN_REFERENCE_TYPE,
        },
      ],
    };

    yield call(postDataToService, postUrl, billingInfo, 'subscriptions');
    yield put({
      type: STORE_BILLING_INFO_IN_STORE,
      selectedBillingInfo: action.payload.selectedBillingInfo,
    });
    yield put({ type: LOADING_COUNTRY });
    yield put(actions.verifyBillingInfoStripeSuccess(true));
    history.push('/order-summary');
  } catch (error: any) {
    yield put(actions.verifyBillingInfoStripeFailure(error));
  }
}

function* fetchClientSecretSaga(): Generator<any, void, any> {
  try {
    const response = yield call(postDataToService, '/setup-intent', {}, 'subscriptions');

    if (response?.clientSecret) {
      yield put(actions.fetchClientSecretSuccess(response.clientSecret));
    } else {
      throw new Error('Failed to fetch client secret');
    }
  } catch (error: any) {
    yield put(actions.fetchClientSecretFailure(error));
  }
}

function* fetchBillingInfoStripeSaga(action: any): Generator<any, void, any> {
  try {
    const paymentMethodId = action.payload;
    const response = yield call(postDataToService, '/payment-method', { paymentMethodId }, 'subscriptions');

    if (response) {
      const billingInfo: any = {
        gatewayToken: response.id,
        firstName: response.billing_details.name.split(' ')[0] || '',
        lastName: response.billing_details.name.split(' ')[1] || '',
        street1: response.billing_details.address.line1 || '',
        street2: response.billing_details.address.line2 || '',
        city: response.billing_details.address.city || '',
        region: response.billing_details.address.state || '',
        postalCode: response.billing_details.address.postal_code || '',
        country: response.billing_details.address.country || '',
      };

      yield put(actions.fetchBillingInfoStripeSuccess(billingInfo));
      // Call onComplete with the billing info
      if (action.onComplete) {
        action.onComplete(billingInfo);
      }
    } else {
      throw new Error('Failed to fetch billing information');
    }
  } catch (error: any) {
    yield put(actions.fetchBillingInfoStripeFailure(error));
  }
}

function* stripeSaga() {
  yield all([
    takeLatest(types.STORE_BILLING_INFO_STRIPE_ATTEMPT, storeBillingInfoStripeSaga),
    takeLatest(types.VERIFY_BILLING_INFO_STRIPE_ATTEMPT, verifyBillingInfoStripeSaga),
    takeLatest(types.FETCH_CLIENT_SECRET_ATTEMPT, fetchClientSecretSaga),
    takeLatest(types.FETCH_BILLING_INFO_STRIPE_ATTEMPT, fetchBillingInfoStripeSaga),
  ]);
}
export default stripeSaga;
