import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import countries from '../BillingInformationView/countries.json';

import {
  BILLING_INFO_LOADED,
  BILLING_INFO_VIEW_LOADED,
  COUNTRY_LOADED,
  LOADING_BILLING_INFO,
  LOADING_BILLING_INFO_VIEW,
  LOADING_COUNTRY,
  SAVING_BILLING_INFO_FAILURE,
  SAVING_BILLING_INFO_SUCCESS,
  STORE_BILLING_INFO,
  STORE_BILLING_INFO_IN_STORE,
  VERIFY_BILLING_INFO_ATTEMPT,
  VERIFY_BILLING_INFO_FAILURE,
  VERIFY_BILLING_INFO_SUCCESS,
} from './actionTypes';
import { getDataFromService, postDataToService } from '../../services/apiGatewayClient';
import { SelectState, TypedIterableIterator } from '../../modules/helpers';
import { getBillingCountrySelector, getBillingInformationToken, getSelectedBillingInfoSelector } from './selectors';
import { AUTHENTICATION_SUCCESS, SOCIAL_AUTHENTICATION_SUCCESS, SSO_SUCCESS } from '../LoginPage/actionTypes';
import { SSO_AUTHENTICATION_SUCCESS } from '../SsoRedirect/actionTypes';

import { getRecurlyValue } from '../../utils/pepperUtils';
import {
  getBillingProviderSelector,
  getBrandNameSelector,
  getLocaleSelector,
  getLocationSelector,
} from '../BrandProvider/selectors';

import { checkMigrationRequired } from '../SubscriptionsList/sagas';

import { brandAllowedCurrency, localeFormats } from '../../components/i18n/constants';
import { SUBSCRIPTION_SUCCESS } from '../OrderSummary/actionTypes';
import { STRIPE_ELEMENTS } from '../../constants/providerNames';

function invokeCallback(callback: any, err: any) {
  if (callback) {
    callback(err);
  }
}

function* verifyBillingInformation(billingInfo: any): TypedIterableIterator<any> {
  try {
    const billingToken = billingInfo.billingInformation.token;
    const payload: any = {
      billing_token: billingToken,
    };

    if (billingInfo.actionResultTokenId) {
      payload.threeDToken = billingInfo.actionResultTokenId;
    }

    yield call(postDataToService, '/billing/verify', payload, 'subscriptions');
    yield put({ type: VERIFY_BILLING_INFO_SUCCESS });
    yield put({
      type: STORE_BILLING_INFO_IN_STORE,
      selectedBillingInfo: billingInfo.billingInformation,
    });
    yield put({ type: LOADING_COUNTRY });
    yield call(invokeCallback, billingInfo.callback, undefined);
  } catch (err) {
    yield put({ type: VERIFY_BILLING_INFO_FAILURE, err });
    yield call(invokeCallback, billingInfo.callback, err);
  }
}

function populateSelectedBillingInfo(billingInfo: any, billingToken: string) {
  return {
    first_name: getRecurlyValue(billingInfo.first_name),
    last_name: getRecurlyValue(billingInfo.last_name),
    address1: getRecurlyValue(billingInfo.address1),
    address2: getRecurlyValue(billingInfo.address2),
    city: getRecurlyValue(billingInfo.city),
    state: getRecurlyValue(billingInfo.state),
    postal_code: getRecurlyValue(billingInfo.zip),
    country: getRecurlyValue(billingInfo.country),
    cardNumberLast4: getRecurlyValue(billingInfo.last_four),
    token: billingToken || '',
  };
}

function* setupBillingInformationView(): TypedIterableIterator<any> {
  yield put({ type: LOADING_BILLING_INFO });
  const selectedBillingInfo: any = yield SelectState<any>(getSelectedBillingInfoSelector);
  const billingProvider = yield SelectState<any>(getBillingProviderSelector);

  if (!selectedBillingInfo || !selectedBillingInfo.size) {
    try {
      const path = billingProvider === STRIPE_ELEMENTS ? '/billingInfoV3' : '/billingInfo';
      const billingInfo = yield call(getDataFromService, path, 'subscriptions');
      // billingInfo will not include billing token so could be overwritten below.  Fetch it and use it to populate if present.
      const billingToken = yield select(getBillingInformationToken);
      const selectedBillingInfo = populateSelectedBillingInfo(billingInfo, billingToken);

      yield put({
        type: BILLING_INFO_LOADED,
        billingInfo,
        selectedBillingInfo,
      });
    } catch (err) {
      yield put({
        type: BILLING_INFO_LOADED,
        billingInfo: {},
        selectedBillingInfo,
      });
    }
  }
  yield put({ type: LOADING_COUNTRY });
  yield put({ type: BILLING_INFO_VIEW_LOADED });
}

function* storeBillingInformation(billingInfo: any): TypedIterableIterator<any> {
  try {
    const billingToken = billingInfo.selectedBillingInfo.token;
    let postUrl = `/billingInfo/${billingToken}`;

    if (billingInfo.actionResultTokenId) {
      postUrl = `${postUrl}/threeDToken/${billingInfo.actionResultTokenId}`;
    }

    yield call(postDataToService, postUrl, {}, 'subscriptions');
    yield put({ type: SAVING_BILLING_INFO_SUCCESS });
    yield put({ type: LOADING_COUNTRY });
    yield call(invokeCallback, billingInfo.callback, undefined);
  } catch (err) {
    yield put({ type: SAVING_BILLING_INFO_FAILURE, err });
    yield call(invokeCallback, billingInfo.callback, err);
  }
}

export function* loadCountry(): TypedIterableIterator<any> {
  const billingCountry: any = yield SelectState<any>(getBillingCountrySelector);
  const browserLocale: any = yield SelectState<any>(getLocaleSelector);
  const browserLocation: any = yield SelectState<any>(getLocationSelector);
  const brand: any = yield SelectState<any>(getBrandNameSelector);
  let contactInfoCountryCode = null;

  try {
    const contactInfoCountryResponse = yield call(getDataFromService, '/contactInfo', 'account');

    const country = contactInfoCountryResponse?.address?.[0]?.country;
    if (country) {
      // Take country name string and retrieve two-letter country code from countries.json
      contactInfoCountryCode = Object.keys(countries).find(key => countries[key as keyof typeof countries] === country);
    }
  } catch (err) {
    contactInfoCountryCode = null;
  }

  let currencyCountry = 'en';
  let currency = 'USD';

  if (billingCountry && brandAllowedCurrency[brand][billingCountry] && localeFormats[billingCountry]) {
    currencyCountry = billingCountry;
    currency = localeFormats[billingCountry].number.LOCALE_CURRENCY.currency;
  } else if (
    contactInfoCountryCode &&
    brandAllowedCurrency[brand][contactInfoCountryCode] &&
    localeFormats[contactInfoCountryCode]
  ) {
    currencyCountry = contactInfoCountryCode;
    currency = localeFormats[contactInfoCountryCode].number.LOCALE_CURRENCY.currency;
  } else if (browserLocation && brandAllowedCurrency[brand][browserLocation] && localeFormats[browserLocation]) {
    currencyCountry = browserLocation;
    currency = localeFormats[browserLocation].number.LOCALE_CURRENCY.currency;
  } else if (browserLocale && brandAllowedCurrency[brand][browserLocale] && localeFormats[browserLocale]) {
    currencyCountry = browserLocale;
    currency = localeFormats[browserLocale].number.LOCALE_CURRENCY.currency;
  }

  yield put({ type: COUNTRY_LOADED, currencyCountry, currency });
  // yield put({ type: CHANGE_CURRENCY, currencyCountry, currency });
}

function* BillingInformationViewSaga() {
  yield all([
    takeLatest(STORE_BILLING_INFO, storeBillingInformation),
    takeLatest(VERIFY_BILLING_INFO_ATTEMPT, verifyBillingInformation),
    takeLatest(LOADING_BILLING_INFO_VIEW, setupBillingInformationView),
    takeLatest(AUTHENTICATION_SUCCESS, setupBillingInformationView),
    takeLatest(SSO_SUCCESS, setupBillingInformationView),
    takeLatest(SSO_AUTHENTICATION_SUCCESS, setupBillingInformationView),
    takeLatest(SUBSCRIPTION_SUCCESS, setupBillingInformationView),
    takeLatest(SOCIAL_AUTHENTICATION_SUCCESS, setupBillingInformationView),
    takeLatest(AUTHENTICATION_SUCCESS, checkMigrationRequired),
    takeLatest(SSO_AUTHENTICATION_SUCCESS, checkMigrationRequired),
    takeLatest(SSO_SUCCESS, checkMigrationRequired),
    takeLatest(LOADING_COUNTRY, loadCountry),
  ]);
}

export default BillingInformationViewSaga;
