// export default CheckoutForm;
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import PromoCode from '../PromoCode';
import { useSelector } from 'react-redux';
import { getBillingInfoSelector } from '../BillingInformationView/selectors';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles({
  form: {
    padding: '32px',
    margin: '20px 0',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)',
  },
  button: {
    backgroundColor: '#635BFF',
    color: 'white',
    padding: '14px 24px',
    marginTop: '24px',
    fontWeight: 600,
    borderRadius: '8px',
    width: '100%',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#524DDB',
      transform: 'translateY(-1px)',
    },
    '&:disabled': {
      backgroundColor: '#A5A5A5',
      cursor: 'not-allowed',
    },
  },
  errorAlert: {
    marginTop: '16px',
    '& .MuiAlert-message': {
      width: '100%',
    },
  },
  errorList: {
    margin: '8px 0 0 16px',
    padding: 0,
  },
});

interface Props {
  brand: string;
  showPromoSection?: boolean;
  userInfo?: any;
  onComplete: (billingInfo: any) => void;
  ref: React.RefObject<any>;
}

const getUserFriendlyError = (error: any): string => {
  // Common payment errors
  if (error?.type === 'card_error') {
    switch (error?.code) {
      case 'card_declined':
        return 'Your card was declined. Please try another card.';
      case 'expired_card':
        return 'Your card has expired. Please use a different card.';
      case 'incorrect_cvc':
        return 'The security code (CVC) is incorrect. Please check and try again.';
      case 'invalid_number':
        return 'The card number is invalid. Please check and try again.';
      default:
        return 'There was an issue with your card. Please try again or use a different card.';
    }
  }

  // Validation errors
  if (error?.type === 'validation_error') {
    return 'Please check your payment information and try again.';
  }

  // Generic errors
  if (error?.message?.includes('confirmation token')) {
    return "We couldn't process your payment at this time. Please try again later.";
  }

  // Default error
  return 'Something went wrong. Please try again later or contact support if the problem persists.';
};

const CheckoutForm = forwardRef(({ brand, showPromoSection, userInfo, onComplete }: Props, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const billingInfo = useSelector(getBillingInfoSelector);
  const classes = useStyles();
  const [errors, setErrors] = useState<string[]>([]);

  const handleError = (error: any) => {
    const userFriendlyMessage = getUserFriendlyError(error);
    setErrors([userFriendlyMessage]);
  };

  const submit = async () => {
    setErrors([]);

    if (!stripe || !elements) {
      setErrors(['The payment system is temporarily unavailable. Please refresh the page and try again.']);
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        throw submitError;
      }

      const currentOrigin = window.location.origin;
      const returnUrl = `${currentOrigin}/subscriptions`;

      const tokenResponse = await stripe.createConfirmationToken({
        elements,
        params: {
          return_url: returnUrl,
        },
      });

      if (tokenResponse.error) {
        handleError(tokenResponse.error);
        throw tokenResponse.error;
      }

      const confirmationToken = tokenResponse.confirmationToken?.id;
      if (!confirmationToken) {
        throw new Error('payment_processing_error');
      }

      const billingInfo = {
        token: tokenResponse.confirmationToken.id,
        first_name: tokenResponse.confirmationToken.payment_method_preview.billing_details.name?.split(' ')[0] || '',
        last_name: tokenResponse.confirmationToken.payment_method_preview.billing_details.name?.split(' ')[1] || '',
        address1: tokenResponse.confirmationToken.payment_method_preview.billing_details.address?.line1 || '',
        address2: tokenResponse.confirmationToken.payment_method_preview.billing_details.address?.line2 || '',
        city: tokenResponse.confirmationToken.payment_method_preview.billing_details.address?.city || '',
        state: tokenResponse.confirmationToken.payment_method_preview.billing_details.address?.state || '',
        postal_code: tokenResponse.confirmationToken.payment_method_preview.billing_details.address?.postal_code || '',
        country: tokenResponse.confirmationToken.payment_method_preview.billing_details.address?.country || '',
        cardNumberLast4: tokenResponse.confirmationToken.payment_method_preview.card?.last4 || '',
        brand: tokenResponse.confirmationToken.payment_method_preview.card?.brand || '',
      };

      onComplete(billingInfo);
    } catch (err: any) {
      handleError(err);
      throw err;
    }
  };

  useImperativeHandle(ref, () => ({
    submit,
  }));

  return (
    <form id="payment-form" className={classes.form}>
      {errors.length > 0 && (
        <Alert
          style={{ marginBottom: 10 }}
          severity="error"
          className={classes.errorAlert}
          onClose={() => setErrors([])}>
          <AlertTitle>Error</AlertTitle>
          {errors.length === 1 ? (
            errors[0]
          ) : (
            <ul className={classes.errorList}>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}
        </Alert>
      )}

      <AddressElement
        id="address-element"
        options={{
          mode: 'billing',
          defaultValues: {
            name:
              billingInfo?.first_name && billingInfo?.last_name
                ? billingInfo?.first_name + ' ' + billingInfo?.last_name
                : '',
            address: {
              line1: billingInfo?.address1 || '',
              line2: billingInfo?.address2 || '',
              city: billingInfo?.city || '',
              state: billingInfo?.state || '',
              postal_code: billingInfo?.zip || '',
              country: billingInfo?.country || '',
            },
          },
        }}
      />
      <PaymentElement
        id="payment-element"
        options={{
          fields: {
            billingDetails: {
              name: 'auto',
              address: {
                line1: 'auto',
                line2: 'auto',
                city: 'auto',
                state: 'auto',
                postalCode: 'auto',
                country: 'auto',
              },
            },
          },
          wallets: { applePay: 'auto', googlePay: 'auto' },
          layout: 'accordion',
          paymentMethodOrder: ['googlePay', 'applePay', 'card'], // Prioritize Google Pay
        }}
      />

      {showPromoSection && (
        <div style={{ marginTop: 25 }}>
          <PromoCode brand={brand} />
        </div>
      )}
    </form>
  );
});

export default CheckoutForm;
